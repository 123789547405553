.gear {
  img {
    transform: scale(0.9);
    transition: all .2s ease-in-out;

    @include on-event() {
      transform: scale(1);
    }
  }
}
