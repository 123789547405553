.schedule {
  .event {
    @include card-container($color: $white, $border: 0, $background: rgba(0, 0, 0, 0.3));
    font-size: rem-calc(16);

    &::after {
      display: block;
      width: 100%;
      height: 5px;
      background-color: #260e4b !important;
      background-image: linear-gradient(to right, $primary-color, $primary-dark-color);
      content: '';
    }

    .event-header {
      @include card-divider($background: none, $padding: rem-calc(20 0));
    }

    h3 {
      font-size: rem-calc(16);
      font-weight: 700;
      letter-spacing: 4px;
      text-align: center;
      text-transform: uppercase;
    }

    .event-content {
      @include card-section(rem-calc(20 0));
    }
  }
}
