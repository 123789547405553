.cta-container {
  margin: rem-calc(20 0);

  .cta {
    @include card-container($color: $white, $border: 0, $background: rgba(0, 0, 0, 0.3));
    font-size: rem-calc(16);

    a {
      @include on-event() {
        color: $white;
      }
    }

    a p {
      color: $white;
    }

    &::after {
      display: block;
      width: 100%;
      height: 5px;
      background-color: #260e4b !important;
      background-image: linear-gradient(to right, $primary-color, $primary-dark-color);
      content: '';
    }

    .cta-header,
    .cta-content {
      margin: rem-calc(20 0);
    }

    .cta-title {
      font-size: rem-calc(22);
      font-weight: 700;
      color: $white;
      text-transform: uppercase;
    }
  }
}
