.site-header {
  padding: rem-calc(8 0);
  background: rgba(32, 22, 9, 0.9);

  .site-name {
    margin-bottom: 0;
    font-size: rem-calc(24);
    letter-spacing: 4px;

    & > a {
      color: $white;

      & > .fas {
        color: $primary-color;
      }
    }
  }
}
