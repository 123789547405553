.home-top {
  height: 500px;
  margin-bottom: 40px;
  padding: 0 0 100px;
  background-image: url('//img.sixteenbit.com/1920x400');
  background-size: cover;
  background-position: center;
}

.section {
  width: 100%;
  min-height: 800px;
  padding: 100px 0 0;
  font-size: rem-calc(14);
  text-align: center;
}

.section-header {
  margin: rem-calc(48 0);
}

.section-title {
  margin: rem-calc(20 0);
  font-size: rem-calc(21);
  font-weight: 700;
  letter-spacing: 4px;
  text-transform: uppercase;
}
