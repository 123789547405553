body {
  background: radial-gradient(circle 50vh at 50% 100%, $primary-dark-color, $black) no-repeat fixed;
  background-size: cover;
}

a {
  background-color: transparent;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s;
}

.button.primary {
  color: $white;
  text-transform: uppercase;
}

[type="text"], [type="password"], [type="date"], [type="datetime"], [type="datetime-local"], [type="month"], [type="week"], [type="email"], [type="number"], [type="search"], [type="tel"], [type="time"], [type="url"], [type="color"], textarea {
  color: $white;
}
