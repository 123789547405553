body {
  background: radial-gradient(circle 50vh at 50% 100%, #382309, #0a0a0a) no-repeat fixed;
  background-size: cover;
}

a {
  background-color: transparent;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s;
}

.button.primary {
  color: #fefefe;
  text-transform: uppercase;
}

[type="text"], [type="password"], [type="date"], [type="datetime"], [type="datetime-local"], [type="month"], [type="week"], [type="email"], [type="number"], [type="search"], [type="tel"], [type="time"], [type="url"], [type="color"], textarea {
  color: #fefefe;
}

.site-header {
  padding: 0.5rem 0;
  background: rgba(32, 22, 9, 0.9);
}

.site-header .site-name {
  margin-bottom: 0;
  font-size: 1.5rem;
  letter-spacing: 4px;
}

.site-header .site-name > a {
  color: #fefefe;
}

.site-header .site-name > a > .fas {
  color: #ed8509;
}

.site-navigation .menu .is-active {
  color: #ed8509;
}

.site-navigation .menu a {
  margin: 5px 0 0;
  border-top: 0;
  border-left: 1px solid #2d243c;
  font-family: "Orbitron", Gotham, Helvetica Neue, Helvetica, Arial, " sans-serif";
  font-size: 0.6875rem;
  font-weight: 700;
  color: #fefefe;
  text-align: center;
  text-transform: uppercase;
}

.site-navigation .menu a:hover, .site-navigation .menu a:active, .site-navigation .menu a:focus {
  color: #ed8509;
}

.home-top {
  height: 500px;
  margin-bottom: 40px;
  padding: 0 0 100px;
  background-image: url("//img.sixteenbit.com/1920x400");
  background-size: cover;
  background-position: center;
}

.section {
  width: 100%;
  min-height: 800px;
  padding: 100px 0 0;
  font-size: 0.875rem;
  text-align: center;
}

.section-header {
  margin: 3rem 0;
}

.section-title {
  margin: 1.25rem 0;
  font-size: 1.3125rem;
  font-weight: 700;
  letter-spacing: 4px;
  text-transform: uppercase;
}

.site-footer {
  position: relative;
  margin-top: 2.5rem;
  padding: 2.5rem 1.25rem 1.25rem;
  background-color: #0a0a0a;
  text-align: center;
}

.site-footer .social {
  position: relative;
  padding: 20px 0 0;
  text-align: center;
}

.site-footer .social > a {
  display: inline-block;
  margin: 5px;
  font-size: 1.375rem;
  color: #999;
  text-decoration: none;
}

.site-footer .social > a:hover, .site-footer .social > a:active, .site-footer .social > a:focus {
  color: #fefefe;
}

.site-footer .legal {
  padding: 1.25rem;
  position: relative;
  font-size: 0.8125rem;
  color: #666;
}

.site-footer .legal a {
  color: #666;
}

.site-footer .legal a:hover, .site-footer .legal a:active, .site-footer .legal a:focus {
  color: #fefefe;
}

.cta-container {
  margin: 1.25rem 0;
}

.cta-container .cta {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 1rem;
  border: 0;
  border-radius: 0.1875rem;
  background: rgba(0, 0, 0, 0.3);
  box-shadow: none;
  overflow: hidden;
  color: #fefefe;
  font-size: 1rem;
}

.cta-container .cta > :last-child {
  margin-bottom: 0;
}

.cta-container .cta a:hover, .cta-container .cta a:active, .cta-container .cta a:focus {
  color: #fefefe;
}

.cta-container .cta a p {
  color: #fefefe;
}

.cta-container .cta::after {
  display: block;
  width: 100%;
  height: 5px;
  background-color: #260e4b !important;
  background-image: linear-gradient(to right, #ed8509, #382309);
  content: '';
}

.cta-container .cta .cta-header,
.cta-container .cta .cta-content {
  margin: 1.25rem 0;
}

.cta-container .cta .cta-title {
  font-size: 1.375rem;
  font-weight: 700;
  color: #fefefe;
  text-transform: uppercase;
}

.schedule .event {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 1rem;
  border: 0;
  border-radius: 0.1875rem;
  background: rgba(0, 0, 0, 0.3);
  box-shadow: none;
  overflow: hidden;
  color: #fefefe;
  font-size: 1rem;
}

.schedule .event > :last-child {
  margin-bottom: 0;
}

.schedule .event::after {
  display: block;
  width: 100%;
  height: 5px;
  background-color: #260e4b !important;
  background-image: linear-gradient(to right, #ed8509, #382309);
  content: '';
}

.schedule .event .event-header {
  display: flex;
  flex: 0 1 auto;
  padding: 1.25rem 0;
  background: none;
}

.schedule .event .event-header > :last-child {
  margin-bottom: 0;
}

.schedule .event h3 {
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 4px;
  text-align: center;
  text-transform: uppercase;
}

.schedule .event .event-content {
  flex: 1 0 auto;
  padding: 1.25rem 0;
}

.schedule .event .event-content > :last-child {
  margin-bottom: 0;
}

.gear img {
  transform: scale(0.9);
  transition: all .2s ease-in-out;
}

.gear img:hover, .gear img:active, .gear img:focus {
  transform: scale(1);
}
