.site-footer {
  position: relative;
  margin-top: rem-calc(40);
  padding: rem-calc(40 20 20);
  background-color: $black;
  text-align: center;

  .social {
    position: relative;
    padding: 20px 0 0;
    text-align: center;

    & > a {
      display: inline-block;
      margin: 5px;
      font-size: rem-calc(22);
      color: #999;
      text-decoration: none;

      @include on-event() {
        color: $white;
      }
    }
  }

  .legal {
    padding: rem-calc(20);
    position: relative;
    font-size: rem-calc(13);
    color: #666;

    a {
      color: #666;

      @include on-event() {
        color: $white;
      }
    }
  }
}
