.site-navigation {
  .menu {
    .is-active {
      color: $primary-color;
    }

    a {
      margin: 5px 0 0;
      border-top: 0;
      border-left: 1px solid #2d243c;
      font-family: $header-font-family;
      font-size: rem-calc(11);
      font-weight: 700;
      color: $white;
      text-align: center;
      text-transform: uppercase;

      @include on-event() {
        color: $primary-color;
      }
    }
  }
}
